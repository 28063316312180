const paths = [
    {
        slug: 'reactions',
        title: 'Реакции на истории'
    }
]
export default paths.map(path => {
    return {
        path: '/more/'+path.slug,
        component: () => import('@/views/pages/more/'+path.slug+'Page.vue'),
        meta: {requiresAuth: true, title: path.title}
    }
})