// main.js

import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import axios from 'axios'

import "bootstrap/dist/css/bootstrap.min.css"


axios.defaults.baseURL = 'https://api.goodve.net/app';
axios.defaults.withCredentials = false;
axios.defaults.headers['Content-type'] = 'application/json;charset=UTF-8'
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['X-Environment'] = 'prod'

axios.interceptors.request.use(function (config) {
    store.dispatch('setLoading', true);
    return config;
}, function (error) {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    store.dispatch('setLoading', false);
    return response;
}, function (error) {
    return Promise.reject(error);
});


// Импортируем стили и другие необходимые ресурсы
const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));
const settings = JSON.parse(localStorage.getItem('settings'));


const app = createApp(App)

if (token && user) {
    // Если токен существует, используйте его для авторизации пользователя
    store.commit('SET_TOKEN', token);
    store.commit('SET_USER', user);
    store.commit('SET_SETTINGS', settings);
} else {
    // В противном случае, перенаправьте пользователя на страницу авторизации
    router.push('/');
}
    app.use(store)
    app.use(router)
    app.mount('#app');
